import React from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '300',
    width: '100%',
    playerVars: {
        autoplay: 1,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        fs: 0,
    }
  };

  return (
    <YouTube videoId={videoId} opts={opts} onReady={(event) => event.target.playVideo()} />
  );
};

export default VideoPlayer;
import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import VideoPlayer from './components/video/video'

function App() {
  const videoId = 'dQw4w9WgXcQ';
  return (
    <div className="conteiner">
      <div className="logo"></div>
      <VideoPlayer videoId={videoId} />
      <div className="header-image"></div>
      <div className="buttons-container">
        <h1>QUERO FAZER PARTE!</h1>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSccP7awZ5JypMSh7grQ4QCndBgxvw5ninJtCj5rnVK4G23-Dw/viewform">
          <Button className="buttons" variant="outlined">CLIQUE AQUI E SE INSCREVA</Button>
        </a>
        
        <h1>ME INSCREVI, QUERO <br/> PAGAR ONLINE!</h1>
        <Button className="buttons" variant="outlined">CLIQUE AQUI <br/> PARA PAGAR</Button>
      </div>
      <div className="versc">
        <h2>
          E Jesus disse-lhe: Amarás o Senhor teu Deus de todo o teu coração, e de toda a tua alma, e de todo o teu pensamento. Mateus 22:37
        </h2>
      </div>
      <div className="copy">
        Copyright © 2023 Lab IRR BRASIL. All rights reserved.
      </div>
      

    </div>
  );
}

export default App;
